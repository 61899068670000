declare global {
	interface Window {
		bootstrapSettings: any;
	}
}

import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

// load async env json file & run angular
(async () => {
	// load env variables
	const response = await fetch("assets/config/environment.json");
	const json = await response.json();

	window.bootstrapSettings = window.bootstrapSettings || json;

	const { AppModule } = await import("./app/app.module");
	const { environment } = await import("./environments/environment");

	if (environment.production) {
		enableProdMode();
	}

	platformBrowserDynamic()
		.bootstrapModule(AppModule)
		.catch((err) => console.error(err));
})();
